<template>
  <el-container class="h-full bg-gray-50 ">
    <el-header class="flex justify-between bg-blue-800 ">
      <Header></Header>
    </el-header>
    <el-container class="h-full p-0 m-0 bg-blue-800  overflow-hidden relative ">
      <el-aside width="220px" class="h-full bg-blue-800">
        <Sidebar></Sidebar>
      </el-aside>
      <el-main class="rounded-l-3xl bg-gray-50 p-0 m-0">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

export default {
  name: "AuthCoverLayout",

  components: {
    Sidebar,
    Header
  },
  data() {
    return {
      menuList: [],
      title: "",
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const {meta, path} = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(v) {
        let find = this.menuList.find((item) => item.path === v.path);
        if (find) {
          this.title = find.title;
        }
      },
    }
  },
  mounted() {
  },
  methods: {
    /**
     *  @actions:  返回
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/26 0:01
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>


<style lang="scss">
.logo {
  height: 60px;
  padding: 10px;
  color: #FFFFFF;
  font-size: 1.2em;

  img {
    width: 100%;
    height: 100%;
  }
}


.edit-password {
  .el-dialog__body {
    border-top: solid 1px #dcdfe6 !important;
    border-bottom: solid 1px #dcdfe6 !important;
  }
}


</style>
