<template>
  <el-scrollbar wrap-class="scrollbar-wrapper" class="h-full">
    <div class="rounded-xl bg-white overflow-hidden m-6">
      <slot></slot>
    </div>
  </el-scrollbar>
</template>
<script>
export default {
  name: "Scrollbar"
};
</script>
<style scoped>

</style>
