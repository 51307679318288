<template>

    <div :class="`rounded-xl bg-white overflow-hidden `">
      <slot></slot>
    </div>

</template>
<script>
export default {
  name: "Scrollbar"
};
</script>
<style scoped>

</style>
