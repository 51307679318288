/*
=========================================================
* HanKun Cloud Workflow PRO - v1.0.0
=========================================================

* Product Page:  https://workflow.hk.run
* Copyright 2022 Creative Tim (https://www.hk.run)
* Coded by www.hk.run

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Cloud from "../cloud";
import App from "./App.vue";
import router from "./router";
import element from "./plugins/element";
import "@/plugins/tailwind.css";
import "@/plugins/icon/index.css";
//引入字体
import '@/assets/font/font.css'

new Cloud({
  element,
  router,
  render: (h) => h(App),
}).$mount("#app");
