<template>
  <el-container class="bg-gray-50 web-cover-layout">
    <el-header class="flex justify-between pt-4 ">
      <Header mode="full"></Header>
    </el-header>
    <el-main class="m-0 p-0">
      <div>
        <div style="width: 1200px;margin: 0 auto">
          <h2 class=" pt-10 school-title-web">{{ config['title'] }} </h2>
          <ul class="mt-12 flex w-full menu-web">
            <a href="/store/preview/index.html" class="menu-item-web" v-if="C('isHome')">首页</a>
            <li @click="handleToMenu(route.path)"
                :class="`menu-item-web ${route.path === activeRoute ? 'menu-item-web_active' : ''}`" :index="route.path"
                v-for="(route) in menuList" :key="route.path">{{ route.title }}
            </li>
          </ul>
          <div class="menu-line-web"></div>
          <!--            <el-menu :default-active="activeMenu" background-color=" rgba(249, 250, 251, var(&#45;&#45;tw-bg-opacity))"-->
          <!--                     class="el-menu-web" mode="horizontal" router>-->
          <!--              <el-menu-item :index="route.path" v-for="(route) in menuList" :key="route.path"-->
          <!--                            :route="{path: route.path}">{{ route.title }}-->
          <!--              </el-menu-item>-->
          <!--            </el-menu>-->
        </div>
      </div>
      <div style="width: 1200px;margin: -3em auto 0;" class=" bg-white p-2 m-0" v-if="display">
        <router-view></router-view>
      </div>
    </el-main>
    <el-footer>
      <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <p class="mt-8 text-center text-base text-gray-400">{{ config['copyright'] }}</p>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

export default {
  name: "WebCoverLayout",
  components: {
    Sidebar,
    Header
  },
  /**
   * 向子组建传递数据
   */
  provide() {
    return {
      WEB: this
    }
  },
  data() {
    return {
      display: false,
      loading: '',
      activeRoute: '',
      menuList: [],
      title: "",
      menuInfo: {}
    };
  },
  computed: {
    config() {
      try {
        return JSON.parse(this.C('value'))
      } catch (e) {
        return {}
      }
    },
    activeMenu() {
      const route = this.$route;
      const {meta, path} = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(v) {
        this.activeRoute = v.path
        let find = this.menuList.find((item) => item.path === v.path);
        if (find) {
          this.menuInfo = find;
          this.title = find.title;
          console.log(' this.menuInfo', this.menuInfo)
        }
      },
    }
  },

  mounted() {
    this.display = false,
    this.loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.handleLoadMenu();     // 加载权限菜单
  },
  methods: {
    getMenuInfo() {
      let {activeMenu} = this;
      return this.menuList.find((item) => item.path === activeMenu)
    },

    handleToMenu(route) {
      this.$router.push({
        path: route,
      });
    },

    /**
     *  @actions:  返回
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/26 0:01
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    goBack() {
      this.$router.go(-1);
    },

    handleIntMenu(route) {
      this.$router.push({
        path: route.path,
        query: {
          ...route
        }
      });
    },


    // 加载权限菜单
    async handleLoadMenu() {
      await this.$cloud.get('/account/detail').then(res => {
        this.userInfo = res;
        console.log("加载此处呀")
        if (res && res?.menuList?.length) {
          this.menuList = res.menuList
        }
        this.loading.close();
        this.display = true;
      })
    }
  },
};
</script>


<style lang="scss">
.web-cover-layout {
  background: url("~@/assets/web/top.png") top center no-repeat;
  background-size: 100%;
}


.logo {
  height: 60px;
  padding: 10px;

  img {
    width: 100%;
    height: 100%;
  }
}

.school-title-web {
  font-size: 56px;
  color: #FFFFFF;
  margin-left: -4rem;
  font-family: YouSheBiaoTiHei, Source Han Sans CN-Medium, Source Han Sans CN;
}

.menu-web {
  overflow: hidden;
  overflow-x: auto;
}

.menu-item-web {
  cursor: pointer;
  flex: 0 0 140px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  color: #FFFFFF;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: linear-gradient(#279aee, #99cff7) !important;

  &:hover {
    background-image: linear-gradient(#2831c9, #98d6fe) !important;
  }
}

.menu-item-web_active {
  background-image: linear-gradient(#2831c9, #98d6fe) !important;
}

.menu-line-web {
  height: 6rem;
  background-image: linear-gradient(#99cff7, #ffffff, #ffffff) !important;
}


.edit-password {
  .el-dialog__body {
    border-top: solid 1px #dcdfe6 !important;
    border-bottom: solid 1px #dcdfe6 !important;
  }
}

.el-menu-web.el-menu.el-menu--horizontal {
  border-bottom: 0;

  .el-menu-item {
    padding: 0;
    margin-right: 35px;
  }
}


</style>
