<template>
  <router-view></router-view>
</template>
<script>
import admin from "@/router/admin";
export default {
  watch: {
    $route() {
      this.onIsAdmin(admin);
    }
  },
  methods: {
    onIsAdmin(child) {
      child.map((item) => {
        if (item.children && item.children.length > 0) {
          this.onIsAdmin(item.children);
          return
        }
        if (item.path === this.$route.path || item.path === '/') {
          if(this.$cloud.userInfo().type !== 1){
            return this.$cloud.go('/pages/web/panel')
          }
        }
      })
    }
  }
}
</script>
<style>
html,
body,
#app {

}

.el-card__header {
  border-bottom: 0 !important;
}

.el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.el-menu {
  border-right: 0 !important;
}

.el-tree-node__content {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 10px !important;
}

.el-tree-node__expand-icon {
  height: 40px !important;
  line-height: 40px !important;
}

.el-tree-node__expand-icon.expanded {
  transform: rotate(0deg) !important;
}

.el-number input::-webkit-outer-spin-button,
.el-number input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.el-number input[type="number"] {
  -moz-appearance: textfield;
}
</style>
