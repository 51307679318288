<template>
  <div class="w-full">
    <Title ref="title" v-if="title" :title="title"/>
    <div class="mx-4 mt-4" ref="search" v-if="displaySearch">
      <slot name="search">
        <!--        <el-form :inline="true" :model="formSearch" class="demo-form-inline">-->
        <!--          <el-form-item label="审批人">-->
        <!--            <el-input v-model="formSearch.user" placeholder="审批人"></el-input>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="活动区域">-->
        <!--            <el-select v-model="formSearch.region" placeholder="活动区域">-->
        <!--              <el-option label="区域一" value="shanghai"></el-option>-->
        <!--              <el-option label="区域二" value="beijing"></el-option>-->
        <!--            </el-select>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item>-->
        <!--            <el-button type="primary" @click="onSubmit">查询</el-button>-->
        <!--            <el-button @click="onSubmit">重置</el-button>-->
        <!--          </el-form-item>-->
        <!--        </el-form>-->
      </slot>
    </div>
    <div class="flex justify-between m-4 mt-2" ref="action">
      <slot name="action">
      </slot>
      <div>
        <el-tooltip placement="top" effect="dark" content="密度">
          <el-button type="text" size="medium" class="mx-2" @click="handleSizeTable" icon="el-icon-c-scale-to-original"
          ></el-button>
        </el-tooltip>
        <el-tooltip placement="top" effect="dark" content="展开搜索">
          <el-button type="text" size="medium" class="mx-2" icon="el-icon-search"
                     @click="handleDisplaySearch"></el-button>
        </el-tooltip>
        <el-tooltip placement="top" effect="dark" content="刷新">
          <el-button type="text" size="medium" class="mx-2" @click="handleLoadData"
                     icon="el-icon-refresh-right"></el-button>
        </el-tooltip>

        <el-popover
            placement="bottom"
            width="200"
            trigger="click">
          <div>
            <Checkbox v-model="tableHeader" :options="tableOption" prop-ley="prop" label-key="label"></Checkbox>
          </div>
          <template slot="reference">
            <el-tooltip placement="top" effect="dark" content="列设置">
              <el-button type="text" size="medium" class="mx-2" icon="el-icon-s-operation"></el-button>
            </el-tooltip>
          </template>
        </el-popover>
        <el-tooltip placement="top" effect="dark" content="导出">
          <el-button type="text" size="medium" class="mx-2" @click="handleExportData"
                     icon="el-icon-download"></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="mx-4 mt-4" ref="info">
      <slot name="info">
      </slot>
    </div>
    <div class="my-0" v-if="tableDisplay" v-loading="loading">
      <u-table
          ref="uTable"
          class="school-table"
          use-virtual
          showBodyOverflow="title"
          showHeaderOverflow="title"
          :data="tableData"
          :size="tableSize"
          :border="false"
          :height="height"
          @selection-change="onSelectChange"
          stripe
          style="width: 100%">
        <u-table-column
            type="selection"
            width="55">
        </u-table-column>
        <u-table-column label="编号" type="index" width="100" fixed/>
        <u-table-column
            :width="item.width || 'auto'"
            :prop="item.prop"
            :label="item.label"
            v-for="(item,index) in tableHeader" :key="index">
          <template slot-scope="scope">
            <template v-if="$scopedSlots['table-item-' + item.prop]">
              <slot
                  :name="'table-item-' + item.prop"
                  :row="scope.row"
                  :$index="scope.$index"
              ></slot>
            </template>
            <template v-else>
              {{
                !!scope.row[item.prop] || scope.row[item.prop] === 0
                    ? scope.row[item.prop]
                    : "-"
              }}
            </template>
          </template>
        </u-table-column>

        <u-table-column
            fixed="right"
            v-if="hasAction"
            :label="handleInfo.label"
            :width="handleInfo.width">

          <template slot-scope="scope">
            <slot name="handle" :row="scope.row" :index="scope.$index"></slot>
          </template>
        </u-table-column>
      </u-table>
    </div>
    <div class="my-4 text-right" ref="pagination">
      <el-pagination
          v-if="showPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.page"
          :page-sizes="[20,50,100, 300, 500,1000]"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import * as XLSX from 'xlsx'
import Title from '../Title'

export default {
  name: "Table",
  components: {
    Title
  },
  props: {
    title: {
      type: String,
      default: null
    },
    options: {
      type: [Function, Object]
    },
    loadData: {
      type: [Function, Object]
    },
    change:{
      type: [Function, Object]
    },
    tableName: {
      type: String,
      default: '数据表格'
    },
    handleInfo: {
      type: Object,
      default: () => {
        return {
          label: '操作',
          width: 180,
        }
      }
    },
    hasAction: {
      type: Boolean,
      default: true
    },
    autoLoading: {
      type: Boolean,
      default: false
    },
    showPage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rows:[],
      height: 0,
      //是否全选
      checkAll: false,
      isIndeterminate: true,
      tableHeader2: [],
      //是否显示搜索
      displaySearch: true,
      //是否显示表格
      tableDisplay: false,
      loading: false,
      //搜索表单结构
      formSearch: {
        user: '',
        region: ''
      },
      page: {
        size: 20,
        page: 1,
        total: 0
      },
      tableSize: 'medium',
      tableOption: [],
      tableHeader: [],
      tableData: [
        //     {
        //   date: '2016-05-02',
        //   name: '王小虎',
        //   address: '上海市普陀区金沙江路 1518 弄'
        // }, {
        //   date: '2016-05-04',
        //   name: '王小虎',
        //   address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //   date: '2016-05-01',
        //   name: '王小虎',
        //   address: '上海市普陀区金沙江路 1519 弄'
        // }, {
        //   date: '2016-05-03',
        //   name: '王小虎',
        //   address: '上海市普陀区金沙江路 1516 弄'
        // }
      ]
    };
  },
  watch: {
    options: {
      deep: true,//true为进行深度监听,false为不进行深度监听
      handler(newVal) {
        console.log(newVal);
        this.setHeight()
      }
    },
  },
  mounted() {
    this.tableDisplay = false;
    if (typeof this.options === 'function') {
      this.options.apply(this, [this])
    } else if (typeof this.options === 'object') {
      let header = this.options.header ?? [];
      let search = this.options.search ?? [];
      this.setHeader(header)
      this.setSearch(search)
    }
    this.setHeight()
  },
  methods: {
    onSelectChange(rows){
      this.$emit('change',rows)
      // if(this.change){
      //   let data = {
      //     rows
      //   }
      //   this.change.apply(this,[this,data]);
      // }
    },

    setHeight() {
      this.$nextTick(()=>{
        let titleHeight = this.$refs?.title?.offsetHeight || 0
        let searchHeight = this.$refs?.search?.offsetHeight || 0
        let actionHeight = this.$refs?.action?.offsetHeight || 0
        let paginationHeight = this.$refs?.pagination?.offsetHeight || 0
        let infoHeight = this.$refs?.info?.offsetHeight || 0
        let fixedHeight = this.$route.path.includes('pages/web') ? 290 : 225;
        let offset = fixedHeight + titleHeight + searchHeight + actionHeight + paginationHeight + infoHeight
        this.height = window.innerHeight - offset
      })
    },
    /**
     * 设置搜索栏
     */
    setSearch() {

    },
    /**
     * 设置表头
     */
    setHeader(val) {
      this.tableOption = JSON.parse(JSON.stringify(val));
      this.tableHeader = val;
      this.$nextTick(() => {
        this.tableDisplay = true
      })
      if (typeof this.loadData === 'function' && this.autoLoading) {
        console.log("val", val)
        this.handleLoadData();
      }
    },
    /**
     * 设置分页
     * @param val
     */
    setPage() {

    },
    /**
     * 设置报错信息
     * */
    errMsg(error){
      let err = error?.response?.data?.msg || error?.response?.data?.data
      this.$message.error(err)
    },

    /**
     * 设置表格数据
     */
    setData(data) {
      let {children, total} = data;
      this.tableData = children
      this.page.total = total
      this.loading = false
    },
    handleLoadData() {
      this.loading = true
      this.loadData.apply(this, [this, this.page, this.formSearch]);
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.handleLoadData();
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.handleLoadData();
    },
    onSubmit() {
      console.log('submit!');
    },
    //是否显示搜索栏
    handleDisplaySearch() {
      this.displaySearch = !this.displaySearch;
    },
    //调整表格密度
    handleSizeTable() {
      if (this.tableSize === 'medium') {
        this.tableSize = 'mini'
      } else {
        this.tableSize = 'medium'
      }
    },
    handleExportData() {
      const headers = [];
      const hedaerKey = []
      for (const index in this.tableHeader) {
        let item = this.tableHeader[index];
        if (item.prop !== 'action') {
          headers.push(item.label)
          hedaerKey.push(item.prop)
        }
      }
      const data = this.tableData.map(item => {
        console.log("item", hedaerKey)
        let values = []
        hedaerKey.forEach(key => {
          if (item[key] && key !== 'action') {
            values.push(item[key])
          }
        })
        return values
      })
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data])
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      XLSX.writeFile(workbook, this.tableName + '.xlsx')
    },
    getCheckboxRecords () {
      console.log(this.$refs.uTable.getCheckboxRecords())
      return this.$refs.uTable.getCheckboxRecords()
    },
    resetPage(){
      this.page.page = 1
      this.page.total = 0
    }
  }
};
</script>
<style lang="scss">
.school-table.plTableBox .el-table th {
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
</style>
