import Empty from "./empty";

export default [
    {
        path: "/pages/panel/panel",
        name: "admin-panel",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "数据大屏",
        },
        component: () => import("../pages/panel/panel.vue"),
    },
    {
        path: "/pages/iusers/iusers",
        name: "admin-iusers",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "师生管理",
        },
        component: Empty,
        children: [
            {
                path: "/pages/iusers/teacher",
                name: "admin-teacher",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "教师管理",
                },
                component: () => import("../pages/iusers/teacher.vue"),
            },
            {
                path: "/pages/iusers/student",
                name: "admin-student",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "学生管理",
                },
                component: () => import("../pages/iusers/student.vue"),
            },
        ]
    },

    {
        path: "/pages/teaching/teaching",
        name: "admin-teaching",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "教务管理",
        },
        component: Empty,
        children: [
            {
                path: "/pages/teaching/semester",
                name: "admin-semester",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "学期管理",
                },
                component: () => import("../pages/teaching/semester.vue"),
            },
            {
                path: "/pages/teaching/classroom",
                name: "admin-classroom",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "班级管理",
                },
                component: () => import("../pages/teaching/classroom.vue"),
            },
            {
                path: "/pages/teaching/gradeteacher",
                name: "admin-gradeteacher",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "年级组长管理",
                },
                component: () => import("../pages/teaching/gradeteacher.vue"),
            },
            {
                path: "/pages/teaching/courseeacher",
                name: "admin-courseeacher",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "备课组长管理",
                },
                component: () => import("../pages/teaching/courseeacher.vue"),
            },
            {
                path: "/pages/teaching/headteacher",
                name: "admin-headteacher",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "班主任管理",
                },
                component: () => import("../pages/teaching/headteacher.vue"),
            },
            {
                path: "/pages/teaching/instructor",
                name: "admin-instructor",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "任课管理",
                },
                component: () => import("../pages/teaching/instructor.vue"),
            },
            {
                path: "/pages/teaching/subject",
                name: "admin-subject",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "学科管理",
                },
                component: () => import("../pages/teaching/subject.vue"),
            },
            {
                path: "/pages/teaching/task",
                name: "admin-task",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "任务管理",
                },
                component: () => import("../pages/teaching/task.vue"),
            },
            {
                path: "/pages/teaching/progress",
                name: "admin-task",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "进度查询",
                },
                component: () => import("../pages/teaching/progress.vue"),
            },
        ]
    },
    {
        path: "/pages/model/model",
        name: "admin-model",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "模块管理",
        },
        component: Empty,
        children: [
            {
                path: "/pages/model/model",
                name: "admin-semester",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "模块管理",
                },
                component: () => import("../pages/model/model.vue"),
            }, {
                path: "/pages/menus/menus",
                name: "admin-menus",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "菜单管理",
                },
                component: () => import("../pages/menus/menus.vue"),
            },
            // {
            //     path: "/pages/teaching/vdoing",
            //     name: "admin-vdoing",
            //     meta: {
            //         active: 1,
            //         icon: "el-icon-ali-panel",
            //         title: "维度指标管理",
            //     },
            //     component: () => import("../pages/teaching/vdoing.vue"),
            // }
        ]

    },
    {
        path: "/pages/model/model_add",
        name: "admin-model-add",
        hidden: true,
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "添加模型",
        },
        component: () => import("../pages/model/model_add.vue"),
    },
    {
        path: "/pages/model/model_edit",
        name: "admin-model-edit",
        hidden: true,
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "添加模型",
        },
        component: () => import("../pages/model/model_edit.vue"),
    },
    {
        path: "/pages/parents/parents",
        name: "admin-parents",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "家长管理",
        },
        component: () => import("../pages/parents/parents.vue"),
    },
    // {
    //     path: "/pages/complaint/complaint",
    //     name: "admin-complaint",
    //     meta: {
    //         active: 1,
    //         icon: "el-icon-ali-panel",
    //         title: "申诉管理",
    //     },
    //     component: () => import("../pages/complaint/complaint.vue"),
    // },
    {
        path: "/pages/updataterm/updataterm",
        name: "admin-updataterm",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "升学管理",
        },
        component: () => import("../pages/updataterm/updataterm.vue"),
    },
    {
        path: "/pages/award/award",
        name: "admin-award",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "幸福卡设置",
        },
        component: Empty,
        children: [
            {
                path: "/pages/award/record",
                name: "admin-record",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "幸福卡发放记录",
                },
                component: () => import("../pages/award/record.vue"),
            },
            {
                path: "/pages/award/medal",
                name: "admin-medal",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "幸福卡管理",
                },
                component: () => import("../pages/award/medal.vue"),
            },
            {
                path: "/pages/award/conversion",
                name: "admin-conversion",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "升级设置",
                },
                component: () => import("../pages/award/conversion.vue"),
            },


        ]
    },
    {
        path: "/pages/comment/comment",
        name: "admin-comment",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "评语管理",
        },
        component: Empty,
        children: [
            {
                path: "/pages/comment/library",
                name: "admin-comment-library",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "系统评语库",
                },
                component: () => import("../pages/comment/library.vue"),
            },
            // {
            //     path: "/pages/comment/deadline",
            //     name: "admin-comment-deadline",
            //     meta: {
            //         active: 1,
            //         icon: "el-icon-ali-panel",
            //         title: "评语截止时间",
            //     },
            //     component: () => import("../pages/comment/deadline.vue"),
            // },
        ]
    },
    {
        path: "/pages/growth/growth",
        name: "admin-growth",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "成长档案管理",
        },
        component: Empty,
        children: [
            {
                path: "/pages/growth/statistics",
                name: "admin-growth-statistics",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "成绩统计",
                },
                component: () => import("../pages/growth/statistics.vue"),
            },
            {
                path: "/pages/growth/custom",
                name: "admin-growth-custom",
                meta: {
                    active: 1,
                    icon: "el-icon-ali-panel",
                    title: "报告模板",
                },
                component: () => import("../pages/growth/custom.vue"),
            },
            // {
            //     path: "/pages/growth/grade",
            //     name: "admin-growth-grade",
            //     meta: {
            //         active: 1,
            //         icon: "el-icon-ali-panel",
            //         title: "成绩总评设置",
            //     },
            //     component: () => import("../pages/growth/grade.vue"),
            // },
        ]
    },
    {
        path: "/pages/setup/setup",
        name: "admin-setup",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "系统设置",
        },
        component: () => import("../pages/setup/setup.vue"),
    },
];
