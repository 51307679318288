<template>
  <div class="m-0 p-2">
    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
    <el-divider class="my-2"></el-divider>
    <el-checkbox-group v-model="checkedValue" @change="handleCheckedChange">
      <div v-for="(item,index) in options" :key="item[propKey]" class="my-2">
        <el-checkbox :label="item[propKey]">
          {{ item[labelKey] }}
        </el-checkbox>
      </div>
    </el-checkbox-group>
  </div>
</template>
<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      type: [Array, Object]
    },
    options: {
      type: [Array, Object]
    },
    propKey: {
      type: [String],
      default: 'prop'
    },
    labelKey: {
      type: [String],
      default: 'label'
    }
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true
    };
  },
  computed: {
    checkedValue: {
      get() {
        let value = []
        for (const index in this.value) {
          let item = this.value[index];
          value.push(item[this.propKey])
        }
        return value;
      },
      set(data) {
        let value = []
        for (const index in this.options) {
          let item = this.options[index];
          //判断是否再数组
          if (data.includes(item[this.propKey])) {
            value.push(item)
          }
        }
        this.$emit("input", value)
      }
    }
  },
  mounted() {
    this.handleCheckedChange(this.checkedValue)
  },
  methods: {
    getOptionList() {
      let value = []
      for (const index in this.options) {
        let item = this.options[index];
        value.push(item[this.propKey])
      }
      return value;
    },
    handleCheckAllChange(val) {
      console.log("this.getOptionList() ", this.getOptionList())
      this.checkedValue = val ? this.getOptionList() : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.options.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.options.length;
    }
  }
};
</script>
<style scoped>

</style>
