<template>
  <div class="h-full">
    <el-scrollbar wrap-class="scrollbar-wrapper" class="h-full">
      <el-menu
          :default-active="activeMenu"
          :unique-opened="false"
          :collapse-transition="false"
          background-color="#1E40AF"
          text-color="#fff"
          active-text-color="#ffd04b"
          mode="vertical"
          style="margin-top: 20px"
      >
        <sidebar-item
            v-for="route in permission_routes"
            :key="route.path"
            :item="route"
            :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>

import SidebarItem from "./SidebarItem";
import permission_routes from "@/router/admin";

export default {
  components: {SidebarItem},
  computed: {
    permission_routes() {
      return permission_routes;
    },
    activeMenu() {
      const route = this.$route;
      const {meta, path} = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    variables() {
      return variables;
    }
  },
};
</script>

<style lang="scss">


</style>
