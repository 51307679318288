import Empty from "./empty";

export default [
    {
        path: "/pages/web/panel",
        name: "web-portrayal",
        meta: {
            active: 1,
            icon: "el-icon-ali-demo",
            title: "学生画像",
        },
        component: () => import("../pages/web/panel.vue"),
    },
    {
        path: "/pages/web/remark",
        name: "web-remark",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "评语管理",
        },
        component: () => import("../pages/web/remark.vue"),
    },
    {
        path: "/pages/web/grant",
        name: "web-grant",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "日常发卡",
        },
        component: () => import("../pages/web/grant.vue"),
    },
    {
        path: "/pages/web/practice",
        name: "web-practice",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "实践活动",
        },
        component: () => import("../pages/web/practice.vue"),
    },
    {
        path: "/pages/web/artistic",
        name: "web-artistic",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "艺术素养",
        },
        component: () => import("../pages/web/artistic.vue"),
    },
    {
        path: "/pages/web/morality",
        name: "web-morality",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "品德发展",
        },
        component: () => import("../pages/web/morality.vue"),
    },
    {
        path: "/pages/web/study",
        name: "web-study",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "学业水平",
        },
        component: () => import("../pages/web/study.vue"),
    },
    {
        path: "/pages/web/study-view",
        name: "web-study-view",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "课程成绩查看",
        },
        component: () => import("../pages/web/studyView.vue"),
    },
    {
        path: "/pages/web/report",
        name: "web-report",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "学期报告",
        },
        component: () => import("../pages/web/report.vue"),
    },
    {
        path: "/pages/web/task",
        name: "web-task",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "任务发布",
        },
        component: () => import("../pages/web/task.vue"),
    },
    {
        path: "/pages/web/evaluate",
        name: "web-evaluate",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "评价设置",
        },
        component: () => import("../pages/web/evaluate.vue"),
    },

    {
        path: "/pages/web/sport",
        name: "web-sport",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "体测成绩",
        },
        component: () => import("../pages/web/sport.vue"),
    },
    {
        path: "/pages/web/award",
        name: "web-award",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "获奖审核",
        },
        component: () => import("../pages/web/award.vue"),
    },
    {
        path: "/pages/web/attendance",
        name: "web-attendance",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "考勤管理",
        },
        component: () => import("../pages/web/attendance.vue"),
    },
    {
        path: "/pages/web/setting",
        name: "web-setting",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "个人设置",
        },
        component: () => import("../pages/web/setting.vue"),
    },
    {
        path: "/pages/web/activities",
        name: "web-activities",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "活动管理",
        },
        component: () => import("../pages/web/activities.vue"),
    },
    {
        path: "/pages/web/importSchedule",
        name: "web-import-schedule",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "导入进度查询",
        },
        component: () => import("../pages/web/importSchedule.vue"),
    },
    {
        path: "/pages/web/honor",
        name: "web-honor",
        meta: {
            active: 1,
            icon: "el-icon-ali-panel",
            title: "荣誉设置",
        },
        component: () => import("../pages/web/honor.vue"),
    }
];
