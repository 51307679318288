import request from "../utils/request";
import {getToken} from "../utils/auth";
import router from "../router/router";
import Vue from "vue";
import {MessageBox} from "element-ui";

export default class Cloud {
    $org_id = "";

    $object = {};

    constructor(object) {
        console.log("object", object)
        this.$object = object;
    }

    /**
     * 执行登录操作
     * @param url
     * @param userInfo
     * @returns {Promise<unknown>}
     */
    login(url, userInfo) {
        return $account.login(url, userInfo);
    }

    org_id(value) {
        this.$org_id = value;
    }

    userInfo() {
        return $account.state.userInfo;
    }

    request(options) {
        return request(options);
    }

    /**
     * 获取设置Token
     * @returns {*}
     */
    token() {
        return getToken();
    }

    /**
     * 执行弹窗
     */
    dialog({title, component, data = {}, refresh, customClass, success = '保存成功', warning = '保存失败'}) {
        const vm = new Vue();
        const key = 'mn_' + (new Date().getTime());
        const h = vm.$createElement;
        const vNode = h(component, {
            key: key,
            props: {
                value: JSON.parse(JSON.stringify(data))
            },
            $object: this.$object
        });
        //用于处理弹窗功能
        MessageBox({
            title: title || '消息框',
            message: vNode,
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: customClass,
            beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                    instance.confirmButtonLoading = true;
                    instance.confirmButtonText = '执行中...';
                    if (vNode.componentInstance.handleSubmit && (typeof vNode.componentInstance.handleSubmit === 'function')) {
                        let resData = vNode.componentInstance.handleSubmit();
                        //判断是否未异步方法
                        if (resData && Object.prototype.toString.call(resData) === '[object Promise]') {
                            resData.then(data => {
                                this.$object.element.Message({
                                    message: success,
                                    type: 'success'
                                });
                                done();
                                instance.confirmButtonLoading = false;
                            }).catch(error => {
                                if (error) {
                                    this.$object.element.Message({
                                        message: warning,
                                        type: 'warning'
                                    });
                                }
                                instance.confirmButtonText = '确定';
                                instance.confirmButtonLoading = false;
                            })
                        } else {
                            done();
                            instance.confirmButtonLoading = false;
                        }
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                } else {
                    done();
                }
            }
        }).then(action => {
            //判断是否存在刷新方法
            if (refresh && (typeof refresh === 'function')) {
                refresh();
            }
            if (vNode.componentInstance) {
                vNode.componentInstance.$destroy()
            }
        }).catch(error => {
            if (vNode.componentInstance) {
                vNode.componentInstance.$destroy()
            }
        });
    }

    /**
     * 数据POST方法
     * @param url
     * @param params
     * @param data
     * @returns {*}
     */
    upload(url, params = null, data = null) {
        if (url.indexOf("/") === 0) {
            url = url.slice(1);
        }
        //设置数据结构
        if (data === null) {
            data = params;
            params = null;
        }
        //重置URL地址
        url = `/api/` + url;
        return request({
            url: url,
            method: "POST",
            headers: {
                "Content-type": "multipart/form-data",
                "x-api-type": "app"
            },
            params: _.assign({}, params),
            data: data
        });
    }

    /**
     * 数据POST方法
     * @param url
     * @param params
     * @param data
     * @returns {*}
     */
    post(url, params = null, data = null) {
        if (url.indexOf("/") === 0) {
            url = url.slice(1);
        }
        //设置数据结构
        if (data === null) {
            data = params;
            params = null;
        }
        //重置URL地址
        url = `/api/` + url;
        return request({
            url: url,
            method: "POST",
            headers: {
                "x-api-type": "app"
            },
            params: _.assign({}, params),
            data: data
        });
    }

    /**
     * 数据GET方法
     * @param url
     * @param params
     * @returns {*}
     */
    get(url, params = null) {
        if (url.indexOf("/") === 0) {
            url = url.slice(1);
        }
        //重置URL地址
        url = `/api/` + url;
        return request({
            url: url,
            method: "GET",
            headers: {
                "x-api-type": "app"
            },
            params: _.assign({}, params)
        });
    }

    /**
     * 数据POST方法
     * @param url
     * @param size
     * @param page
     * @param params
     * @param data
     * @returns {*}
     */
    page(url, {size = 10, page = 1}, params = null, data = null) {
        if (url.indexOf("/") === 0) {
            url = url.slice(1);
        }
        //设置数据结构
        if (data === null) {
            data = params;
            params = null;
        }
        //重置URL地址
        url = `/api/` + url;
        return request({
            url: url,
            method: "POST",
            headers: {
                "x-api-type": "app"
            },
            params: _.assign(
                {},
                {
                    page,
                    size
                },
                params
            ),
            data: data
        });
    }

    /**
     * 跳转
     */
    go(url, query = null) {
        if (Object.prototype.toString.call(url) === "[object Object]") {
            if (url.query) {
                url.query = Object.assign(url.query, query);
            }
        } else {
            url = {
                path: url,
                query
            };
        }
        console.log("需要跳转的界面", url);
        router.push(url);
    }
}
