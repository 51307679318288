<template>
  <div class="m-4">
    <h1 class="school-title text-xl font-semibold text-blue-800">{{ title }}</h1>
  </div>
</template>
<script>
export default {
  name: "Title",
  /**
   * 调用组建
   * @type {Object}
   */
  components: {},
  /**
   * 组建属性
   * @type {Object}
   */
  props: {
    title: {
      type: String
    }
  },
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面创建时
   * @return {[type]} [description]
   */
  created() {

  },
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {

  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {}
};
</script>
<style lang="scss">
.school-title {
  display: flex;

  &:before {
    content: '';
    display: inline-block;
    width: 5px;
    background: #0d47a1;
    margin-right: 10px;
  }
}
</style>