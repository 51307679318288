import Vue from "vue";
import ElementUI from "element-ui";
import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css';
import Theme from "@/theme/school/components"
import "@/theme/school/styles/index.css";


Vue.use(ElementUI, {size: "small", zIndex: 3000});
Vue.use(UmyUi);
Vue.use(Theme)

export default ElementUI;
