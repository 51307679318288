<!--
 * ======================================
 * 说明： 学期班级选择组件
 * 作者： Silence<
 * 文件： index.vue
 * 日期： 2023/8/27 1:55
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="web-form-label">
    <el-form-item label="学期">
      <el-select class="w-full" v-model="formSearch.semesterId" clearable @change="handleSmester">
        <el-option v-for="item in semesterOptions" :key="item.value" :label="item.semester_name"
                   :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="班级">
      <el-cascader clearable v-model="classId" @change="handleClasses" :options="classesTreeOptions"
                   :props="{value:'id',label:'name'}" :show-all-levels="false"></el-cascader>
    </el-form-item>
    <el-form-item label="" v-if="refreshBtn">
      <el-button type="primary" icon="el-icon-refresh-right" @click="handleRefresh"></el-button>
    </el-form-item>

  </div>
</template>
<script>
export default {
  name: 'SemesterClassSelect',
  components: {},
  props: {
    mode: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {
      }
    },
    refreshBtn:{
      type:Boolean,
      default:true
    }
  },
  inject:['WEB'],
  data() {
    return {
      classId: [],
      semesterOptions: [],
      classesTreeOptions: [],
    }
  },
  computed: {
    formSearch: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
  },
  async mounted() {
    await this.handleLoadSemesterOptions();     // 获取学期选项
  },
  methods: {
    async handleLoadSemesterOptions() {
      await this.$cloud.page("semester/list", {
        size: 99999,
        page: 1,
      }).then(async res => {
        this.semesterOptions = res.children || [];
        this.$set(this.formSearch, 'semesterId', res.children?.[0]?.id);

        // 获取学期后 在获取班级
        await this.handleLoadClassesTree();
      })
    },

    // 获取班级待选树状数据
    async handleLoadClassesTree() {
      if (this.formSearch.semesterId || this.formSearch.semesterId === 0) {
        this.$nextTick(async ()=>{
          let menuInfo = this?.WEB?.getMenuInfo();
          let url = this.mode !== 'view' ? '/classes/list/tree' : '/classes/lesson/tree';
          await this.$cloud.post(url, {
            semesterId: this.formSearch.semesterId,
            blockId:menuInfo?.block_id,
            menuId:menuInfo?.id,
          }).then(res => {
            this.classesTreeOptions = res
            this.$set(this.formSearch, 'classId', res[0]?.children?.[0]?.id);
            this.$set(this.formSearch, 'grade', res[0]?.id)
            this.classId = [res[0]?.id, res[0]?.children?.[0]?.id]
            this.$emit('onLoadFinish',  this.formSearch)
          })
        })
      } else {
        this.classId = []
        this.classesTreeOptions = []
        this.formSearch.classId = ''
        this.formSearch.grade = ''
      }
    },

    async handleSmester() {
      this.formSearch['classId'] = ''
      this.formSearch['grade'] = ''
      this.classId = []
      await this.handleLoadClassesTree()
    },

    handleClasses() {
      this.formSearch['classId'] = ''
      this.formSearch['grade'] = ''
      if (this.classId.length) {
        this.formSearch['grade'] = this.classId[0]
        this.formSearch['classId'] = this.classId[1]
      }
      this.$emit("change",this.formSearch)
    },
    handleRefresh() {
      this.$emit("refresh",this.formSearch)
    }
  },
}
</script>
<style lang="scss">
//.web-form-label {
//  .el-input__inner {
//    background: transparent !important;
//    border: 0 !important;
//  }
//
//  .el-input__suffix {
//    display: none !important;
//  }
//}
</style>
