<template>
  <div class="flex justify-between w-full" :style="widthStyle">
    <div class="flex items-center">
      <div class="w-10 bg-white rounded-full"><img class="w-full" :src="config['logo']" alt="" srcset="" /></div>
      <div class="logo flex items-center justify-center">
        {{ config['name'] }}
        <!--        <img src="@/assets/bg-white.png" alt="" srcset=""/>-->
      </div>
    </div>
    <div class="user flex items-center">
      <a href="http://gpt.jiuanci.com/" v-if="C('isHome')"><i
          style="color: yellow; font-size: 24px; font-weight: 800;padding: 0 20px;">AI</i></a>
      <el-avatar src="http://bsdzx.manong.cloud/store/images/WechatIMG6407.jpg" class="mx-2"></el-avatar>
      <el-popover placement="bottom" width="120" trigger="click">
        <div class="flex flex-col justify-center items-center">
          <div>
            <el-button type="text" icon="el-icon-user-solid" @click="() => (passwordVisible = true)">修改密码
            </el-button>
          </div>
          <div>
            <el-button type="text" icon="el-icon-back" @click="handleBackHome">返回首页
            </el-button>
          </div>
          <div>
            <el-button type="text" icon="el-icon-s-tools" @click="handleGoAdmin">管理后台
            </el-button>
          </div>
          <div>
            <el-button type="text" icon="el-icon-switch-button" @click="handleOutLogin">退出登录
            </el-button>
          </div>
        </div>
        <el-button slot="reference" class="text-white" type="text"> {{ userInfo.username }} <i
            class="el-icon-caret-bottom"></i></el-button>
      </el-popover>
    </div>

    <el-dialog title="修改密码" :visible.sync="passwordVisible" width="30%" v-loading="loading" custom-class="edit-password"
      :before-close="() => (passwordVisible = false)">
      <el-form v-if="passwordVisible" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="旧密码" prop="password">
          <el-input type="password" placeholder="请输入旧密码" v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" placeholder="请输入新密码" v-model="ruleForm.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" placeholder="请再次输入新密码" v-model="ruleForm.checkPass"></el-input>
        </el-form-item>
      </el-form>
      <!--      <el-input-->
      <!--          type="password"-->
      <!--          placeholder="请输入新密码"-->
      <!--          v-model="password"-->
      <!--      ></el-input>-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleEditPassword">修 改</el-button>
      </span>
    </el-dialog>
  </div>


</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "LayoutHeader",
  props: {
    mode: {
      type: String,
      default: 'full'
    }
  },
  data() {
    return {
      menuList: [],
      title: "",
      loading: false,
      password: "", // 新密码
      passwordVisible: false,

      ruleForm: {
        password: "",         // 旧密码
        newPassword: "",      // 新密码
        checkPass: "",        // 确认密码
      },
      rules: {
        password: [
          { required: true, message: "请输入旧密码", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" },
          { validator: this.validatePassword, trigger: "blur" }
        ],
        checkPass: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          { validator: this.validatePassword, trigger: "blur" },
          { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value !== this.ruleForm.newPassword) {
                callback(new Error("两次输入密码不一致"));
              }
              callback();
            }
          }
        ]
      }
    };
  },

  computed: {
    config() {
      try {
        return JSON.parse(this.C('value'))
      } catch (e) {
        return {}
      }
    },

    userInfo() {
      return this.$cloud.userInfo();
    },
    widthStyle() {
      if (this.mode !== 'full') {
        return {
          width: '1200px',
          margin: '0 auto'
        }
      }
      return {}
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  watch: {
    passwordVisible(val) {
      if (val) {
        this.password = "";
      }
    },
  },
  mounted() {

  },
  methods: {
    /**
     *  @actions:   修改登录密码
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/12 23:25
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleEditPassword() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$cloud
            .post("login/password", this.ruleForm)
            .then(() => {
              this.loading = false;
              this.$message.success("修改成功!");
              setTimeout(() => {
                Cookies.remove("hankun_cloud_template_token");
                this.$cloud.go("/pages/login/login");
              });
            })
            .catch((err) => {
              this.loading = false;
              // this.$message.error(err);
            });
        }
      });


      // if (this.password) {
      //   if (this.password.length >= 6) {
      //     this.loading = true;
      //     this.$cloud
      //         .post("account/edit_pass", {
      //           password: this.password,
      //         })
      //         .then(() => {
      //           this.loading = false;
      //           this.$message.success("修改成功！");
      //           setTimeout(() => {
      //             Cookies.remove("hankun_cloud_template_token");
      //             this.$cloud.go("/pages/login/login");
      //           });
      //         })
      //         .catch((err) => {
      //           this.loading = false;
      //           this.$message.error(err);
      //         });
      //   } else {
      //     this.$message.error("密码长度不能小于6位");
      //   }
      // } else {
      //   this.$message.error("请输入新密码");
      // }

    },

    /**
     *  @actions:  返回首页
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/8/30 0:23
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleBackHome() {
      this.$cloud.go('/pages/web/panel')
    },

    /**
     *  @actions:  进入后台管理
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/8/30 0:25
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleGoAdmin() {
      this.$cloud.get('account/detail').then(res => {
        if (res?.type === 1) {
          this.$cloud.go('/pages/panel/panel')
        } else {
          this.$message.error('您不是管理员,无法进入后台管理')
        }
      })
    },


    /**
     *  @actions:  退出登录
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/17 22:49
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleOutLogin() {
      this.$confirm("您确定退出系统吗？", "退出系统", {
        type: "warning",
      })
        .then(() => {
          console.log("退出系统！");
          Cookies.remove("hankun_cloud_template_token");
          this.$cloud.go("/pages/login/login");
        })
        .catch(() => {
          // this.$message.error(err)
        });
    },
    validatePassword(rule, value, callback) {
      let passwordRegex = /^(?=(.*\d){1})(?=(.*[a-z]){1})(?=(.*[A-Z]){1})(?=(.*[\W_]){1}).{8,}$/;
      if (!passwordRegex.test(value)) {
        callback(new Error("密码必须包含数字、大小写字母和特殊字符中的至少3种，且长度至少为8位"));
      } else {
        callback();
      }
    }
  },
};
</script>
