import Table from "./Table";
import Scrollbar from "./Scrollbar";
import Content from "./Content";
import Checkbox from "./Checkbox";
import Dialog from "./Dialog";
import Title from "./Title";
import SemesterClassSelect from './SemesterClassSelect/index.vue'
const components = [
    Dialog,
    Table,
    Scrollbar,
    Content,
    Checkbox,
    Title,
    SemesterClassSelect
];
const install = function (Vue, opts = {}) {
    components.forEach(component => {
        Vue.component(component.name, component);
    });
}

export default {
    version: '2.15.13',
    install
}
