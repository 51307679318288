import AuthCoverLayout from "@/theme/school/AuthCoverLayout";
import WebCoverLayout from "@/theme/school/WebCoverLayout";
import AdminCoverLayout from "@/theme/school/AdminCoverLayout";
import web from "@/router/web";
import admin from "@/router/admin";
export default [
    {
        path: "/",
        component: AuthCoverLayout,
        name: "loginMain",
        redirect: "/pages/login/login",
        children: [
            {
                path: "/pages/login/login",
                name: "login",
                component: () => import("../pages/login/login.vue"),
            },
            {
                path: "/pages/login/ding",
                name: "login",
                component: () => import("../pages/login/ding.vue"),
            },
            {
                path: "/pages/home/home",
                name: "home",
                component: () => import("../pages/home/home.vue"),
            },
        ],
    },
    {
        path: "/web",
        component: WebCoverLayout,
        name: "webLoginMain",
        redirect: "/pages/web/portrayal",
        children: web,
    },
    {
        path: "/admin",
        component: AdminCoverLayout,
        name: "adminLoginMain",
        redirect: "/pages/panel/panel",
        children: admin,
    },
];
