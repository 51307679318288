import Vue from "vue";
import {routerDefend} from "./router/defend";
import lodash from "lodash";
// 路由信息处理
import router from "./router/router";
// 加载方法
import Request from "./request";
// 加载账号处理
import Account from "./account";

export default class cloud {
    constructor(object) {
        const connect = {
            account: new Account(object),
            cloud: new Request(object),
        };
        if (window) {
            window._ = lodash;
            window.$account = connect.account;
            window.$cloud = connect.cloud;
        }
        //注册全局函数
        Vue.prototype.$account = connect.account;
        Vue.prototype.$cloud = connect.cloud;
        Vue.prototype.C = C;
        Vue.config.productionTip = false;
        routerDefend(object.router);
        return new Vue({
            router: router,
            vuetify: object.vuetify || {},
            render: object.render,
        });
    }

    /**
     * 扩展VUE的use方法
     * @param object
     */
    static use(object) {
        Vue.use(object);
    }

    /**
     * 返回默认VUE的实例
     * @returns {Vue | VueConstructor}
     */
    static vue() {
        return Vue;
    }
}
